/* AboutUs.css */

.about-us-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.about-us-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #4a4a4a;
}

p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
}

.table-title {
  font-size: 2rem;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  color: #4a4a4a;
}

.artist-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
}

.artist-table th,
.artist-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.artist-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.artist-table td a {
  color: #007bff;
  text-decoration: none;
}

.artist-table td a:hover {
  text-decoration: underline;
}

.artist-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
