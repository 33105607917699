* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #f0f0f0;
}

@font-face {
  font-family: 'CenturyGothic';
  src: url('./assets/centurygothic.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'CenturyGothic';
  src: url('./assets/centurygothic_bold.ttf') format('truetype');
  font-weight: bold;
}

/* styles.css */

/* Estilos por defecto para escritorio */
.nav-container {
  display: flex;
}

.hamburger-menu {
  display: none;
}

/* Media Queries para pantallas m�viles */
@media screen and (max-width: 768px) {
  /* Ocultar la navegaci�n de escritorio y mostrar el men� hamburguesa */
  .nav-container {
    display: none;
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
    margin-left: auto;
  }

  .hamburger-menu .bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
    transition: 0.4s;
  }

  /* Men� m�vil */
  .mobile-menu {
    display: block;
    background-color: white;
    width: 100%;
    position: absolute;
    top: 70px; /* Ajustar seg�n la altura del header */
    left: 0;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .mobile-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .mobile-menu li {
    border-bottom: 1px solid #ddd;
  }

  .mobile-menu li a {
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #333;
    text-align: center;
  }
}
