/* ContactUs.css */

.contact-us-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.contact-us-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #4a4a4a;
}

.contact-email {
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: center;
}

.contact-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
}

.contact-table th,
.contact-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.contact-table th {
  background-color: #333;
  color: white;
  font-weight: bold;
}

.contact-table td a {
  color: #007bff;
  text-decoration: none;
}

.contact-table td a:hover {
  text-decoration: underline;
}

.contact-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
